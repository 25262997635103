import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Button from "react-bootstrap/Button"
import SEO from "../components/seo"
import A from "../images/sorry_334.jpg"

import "../components/static/styles/404.css"

const divStyles = {
  alignItems: `start`,
  alignContent: `start`,
  backgroundColor: `antiquewhite`,
  display: `grid`,
  gridTemplateColumns: `1fr`,
  gridTemplateRows: `auto`,
  justifyItems: `center`,
  width: `100%`,
}

const btnStyles = {
  zIndex: `1`,
}

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={divStyles} className="page_404_main">
      <h1 className="page_404_headline">NOT FOUND</h1>
      <p style={{ marginBottom: `0.5rem` }} className="page_404_para">
        You just found a link that doesn&#39;t lead anywhere... the sadness.
      </p>
      <img src={A} alt="sorry" className="page_404_img" />
      <Link>
        <Button style={btnStyles} className="page_404_button">
          Home Page
        </Button>{" "}
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
